import React from 'react'
import {Link} from 'gatsby'

import facebook from '../img/social/facebook.svg'
import blogger from '../img/social/blogger.svg'
import twitter from '../img/social/twitter.svg'
import linkedin from '../img/social/linkedin.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <ul className="links">
          <li><Link to="/privacy-policy"> Privacy Policy</Link></li>
          <li><Link to="/terms-of-use"> Terms of Use</Link></li>
          <li><Link to="/site-map"> Site Map</Link></li>
        </ul>
        <div className="part">
          <ul className="social">
            <li><a target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/littlerassociates/"><img src={facebook} alt="facebook"/></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Littlertweets"><img src={twitter} alt="twitter"/></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/littler"><img src={linkedin} alt="linkedin"/></a></li>
            <li><Link to={'/blog'}> <img src={blogger} alt="blogger"/></Link></li>
          </ul>
          <div className="copyright">&#xa9; Littler Associates</div>
        </div>

      </footer>
    )
  }
}

export default Footer
