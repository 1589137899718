import React, {useState} from 'react'
import {Link} from 'gatsby'
import logo from '../img/logo.svg'
/* eslint-disable */
const Navbar = () => {
  const [open, setOpen] = useState(false)

  function toggleChecked() {
    setOpen(!open)
  }

  function isActive(path, exact) {
    return typeof window !== 'undefined' && (exact ? window.location.pathname === path : window.location.pathname.indexOf(path) > -1);
  }

  return (
    <>
      <nav>
        {open &&
        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'transparent', zIndex: 1}}
             onClick={toggleChecked}/>}
        <div className="container">
          <h1 className="logo">
            <Link to="/">
              <img src={logo} alt="logo"/>
            </Link>
          </h1>
          <ul className="desktop-menu">
            <li className={isActive('/', true) ? "active" : ""}><Link to={'/'}>Home</Link></li>
            <li className={isActive('/about-us') ? "active" : ""}><Link to={'/about-us'}>About Us</Link></li>
            <li className={isActive('/clients') ? "active" : ""}><Link to={'/clients'}>Clients</Link></li>
            <li className={isActive('/blog') ? "active" : ""}><Link to={'/blog'}>Blog</Link></li>
            <li className={isActive('/contact') ? "active" : ""}><Link to={'/contact'}>Contact Us</Link></li>
            <li className={isActive('/faq') ? "active" : ""}><Link to={'/faq'}>FAQ's</Link></li>
          </ul>
          <div
            role="navigation"
            className={`${open ? 'menu-btn close' : 'menu-btn'}`}
            onClick={toggleChecked}
          >
            <div className="btn-line"/>
            <div className="btn-line"/>
            <div className="btn-line"/>
          </div>
        </div>
      </nav>
      <ul className={open ? 'menu open' : 'menu'}>
        <li className={isActive('/', true) ? "menu-item active" : "menu-item"}>
          <Link to={'/'}>Home </Link>
        </li>
        <li className={isActive('/what-we-do') ? "menu-item active" : "menu-item"}>
          <Link to={'/what-we-do'}>
            What we <strong>do</strong>
          </Link>
        </li>
        <li className={isActive('/what-we-have-done') ? "menu-item active" : "menu-item"}>
          <Link to={'/what-we-have-done'}>
            {' '}
            What we <strong>have done</strong>
          </Link>
        </li>
        <li className={isActive('/what-we-have-learnt') ? "menu-item active" : "menu-item"}>
          <Link to={'/what-we-have-learnt'}>
            {' '}
            What we <strong>have learnt</strong>
          </Link>
        </li>
        <li className={isActive('/about-us') ? "menu-item active" : "menu-item"}>
          <Link to={'/about-us'}> Who we are</Link>
        </li>
        <li className={isActive('/clients') ? "menu-item active" : "menu-item"}>
          <Link to={'/clients'}> Clients</Link>
        </li>
        <li className={isActive('/faq') ? "menu-item active" : "menu-item"}>
          <Link to={'/faq'}> FAQ's</Link>
        </li>
        <li className={isActive('/blog') ? "menu-item active" : "menu-item"}>
          <Link to={'/blog'}> Blog</Link>
        </li>
        <li className={isActive('/contact') ? "menu-item active" : "menu-item"}>
          <Link to={'/contact'}> Contact Us</Link>
        </li>
      </ul>
    </>
  )
}

export default Navbar
